import { AccountType, ClaimStatusEnum } from "./enums";

export const NO_VALUE_PLACEHOLDER = "—";
export const DATE_FORMAT_PLACEHOLDER = "dd/mm/yyyy";
export const NO_ACCESS_TOKEN_MESSAGE = "No access token";
export const COMMA_SEPARATOR = ",";
export const VALUE_PER_SPLIT_SEPARATOR = "/";
export const DEFAULT_COUNTRY = { id: 225, text: "United Kingdom" };
export const REDIRECT_TIMEOUT_MS = 2 * 60000;
export const NON_EXISTING_EMAIL_DOMAIN = "@non.existing.com";
export const EMPTY_FIELD_VALUE = "";
export const DOT_CHAR = ".";
export const EMPTY_VALUE = "";

export const EMPTY_HISTORY_LENGTH = 2;

export const DATE_FORMATS = {
    DATE_FORMAT: "dd/MM/yyyy",
    DATEPICKER_FORMAT: "dd/MM/yyyy",
    CARD_FORMAT: "MM/yy",
    FILTER_FORMAT: "yyyy-MM-dd",
    TIME_FORMAT: "HH:mm",
    CHAT_HEADER: "d MMMM y",
    LAST_LOGIN_DATE: "HH:mm dd/MM/yyyy",
    DATE_TIME_FORMAT: "dd/MM/yyyy HH:mm",
};

export const CURRENCY_FORMAT = {
    thousandSeparator: true,
    isNumericString: true,
    prefix: "£",
    decimalScale: 2,
    fixedDecimalScale: true,
};

export const PERCENT_FORMAT = {
    isNumericString: true,
    prefix: "%",
    decimalScale: 2,
    fixedDecimalScale: true,
};

export const ACCOUNT_TYPE_NAMES: Record<AccountType, string> = {
    [AccountType.Admin]: "account-types.admin",
    [AccountType.Agent]: "account-types.agent",
    [AccountType.PrivateLandlord]: "account-types.private_landlord",
    [AccountType.CorporateLandlord]: "account-types.corporate_landlord",
    [AccountType.Tenant]: "account-types.tenant",
    [AccountType.None]: "account-types.none",
};

export const VALIDATION_RULES = {
    ALPHA_NUMERIC_FORMAT: /^[A-Za-z\d]+$/,
    BANK_ACCOUNT_NAME_FORMAT: /^[A-Za-z\d\s-/&.]+$/,
    BANK_IDENTIFIER_CODE_MAX_LENGTH: 11,
    BANK_IDENTIFIER_CODE_MIN_LENGTH: 8,
    BANK_ROUTING_NUMBER_LENGTH: 9,
    BANK_SORT_CODE_LENGTH: 6,
    CASE_NOTE_TEXT_MAX_LENGTH: 3_000,
    CASE_NOTE_TEXT_MIN_LENGTH: 1,
    CENTRAL_PHONE_FORMAT: /^((\+[0-9-( )]{3,15})|([0-9-( )]{3,15}))$/,
    CENTRAL_PHONE_MAX_LENGTH: 15,
    CLAIM_REASON_COMMENT_INPUT_MAX_LENGTH: 1000,
    CLAIM_REASON_COMMENT_INPUT_MIN_LENGTH: 5,
    CLAIM_SETTLEMENT_COMMENT_MAX_LENGTH: 1000,
    CLAIM_SETTLEMENT_COMMENT_MIN_LENGTH: 1,
    COMPANY_REGISTRATION_NUMBER_FORMAT: /^[A-Z0-9.() /-]+$/gi,
    COMPANY_REGISTRATION_NUMBER_MAX_LENGTH: 10,
    DEACTIVATION_REASON_MAX_LENGTH: 1000,
    DEFAULT_INPUT_MAX_LENGTH: 255,
    DEFAULT_INPUT_MIN_LENGTH: 0,
    DELETE_USER_REASON_MAX_LENGTH: 1000,
    DELETE_USER_REASON_MIN_LENGTH: 5,
    DIGITS_ONLY_FORMAT: /^\d+$/,
    EMAIL_FORMAT: /^[A-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[A-Z0-9-_]+(\.[A-Z0-9-]+)+$/gi,
    EVIDENCE_COMMENT_INPUT_MAX_LENGTH: 1000,
    INTL_PHONE_NUMBER: /^\+[1-9]\d{0,14}/,
    INVALID_SORT_CODE_CHARS: /[-_]/gi,
    MAX_BANK_ACCOUNT_NAME_LENGTH: 70,
    MAX_CARD_PAYMENT_AMOUNT: 999_999.99,
    MAX_ROLES_COUNT: 20,
    MAX_TRANSFER_PAYMENT_AMOUNT: 999_999_999.99,
    MAX_USERS_COUNT: 20,
    MIN_AGE: 16,
    MIN_CARD_PAYMENT_AMOUNT: 0.3,
    MIN_DATE: new Date(1900, 0, 1),
    MIN_OFFICES_COUNT: 1,
    MIN_ROLES_COUNT: 1,
    MIN_LEAVING_TENANTS: 1,
    MIN_TRANSFER_PAYMENT_AMOUNT: 0.01,
    MIN_USERS_COUNT: 1,
    MISSING_DOCUMENT_REASON_MAX_LENGTH: 1500,
    MISSING_DOCUMENT_REASON_MIN_LENGTH: 5,
    OPTIONAL_PHONE_FORMAT: "(^07\\d{3}\\s{0,1}\\d{6}$|^$)",
    PHONE_FORMAT: "^07\\d{3}\\s{1}\\d{6}$",
    PHONE_VERIFICATION_CODE_FORMAT: "^[1-9]{1}\\s{1}\\d{1}\\s{1}\\d{1}\\s{1}\\d{1}$",
    PROPOSE_REASON_MAX_LENGTH: 1000,
    ADJUDICATOR_RESOLUTION_REASON_MAX_LENGTH: 20000,
    ADJUDICATOR_RESOLUTION_REASON_MIN_LENGTH: 5,
    REJECT_REVIEW_STAT_DEC_COMMENT_MAX_LENGTH: 1000,
    RELEASE_REQUEST_CANCEL_REASON_MAX_LENGTH: 1000,
    CASE_ACCESSOR_RESOLUTION_REASON_MAX_LENGTH: 6000,
    CASE_ACCESSOR_RESOLUTION_REASON_MIN_LENGTH: 5,
    ROLE_DESCRIPTION_MAX_LENGTH: 1000,
    ROUTING_NUMBER_FORMAT: /^(^[0-3])\d+$/,
    SETTLEMENT_COMMENT_MAX_LENGTH: 6000,
    SETTLEMENT_COMMENT_MIN_LENGTH: 5,
    SETTLEMENT_MAX_TOTAL_DEDUCTION: 100_000,
    MAX_DEPOSIT_BALANCE: 100_000,
    TRIM_NO_TAGS_FORMAT: "<[a-zA-Z0-9][^/]*?>.*?</[a-zA-Z0-9]*>|<[a-zA-Z0-9].*?/>",
    VERIFICATION_CODE_RANGE: { MIN: 1000, MAX: 9999 },
    VOUCHER_SETTINGS_FIELD_MIN_VALUE: 1,
    COMPLAINT_REASON_MAX_LENGTH: 6000,
    COMPLAINT_REASON_MIN_LENGTH: 5,
    RELEASE_REQUEST_UPDATE_DOCUMENTS_REASON_MIN_LENGTH: 5,
    RELEASE_REQUEST_UPDATE_DOCUMENTS_REASON_MAX_LENGTH: 1500,
    TRANSFERRED_DEPOSIT_ID_MIN_LENGTH: 1,
    TRANSFERRED_DEPOSIT_ID_MAX_LENGTH: 25,
};

export const SCHEME_CONFIGURATION_VALIDATION_RULES = {
    MAX_PAYMENT_RECEIVER_LENGTH: 4000,
    MIN_NAME_LENGTH: 3,
    MIN_INITIATORS_COUNT: 1,
    MIN_REMINDER_DELAYS_COUNT: 1,
    MAX_DEPOSIT_ID_PREFIX_LENGTH: 3,
    MIN_DEPOSIT_ID_PREFIX_LENGTH: 2,
    MIN_RELEASE_REQUEST_REVIEW_PERIOD: 1,
    MIN_COURT_TIME_PERIOD: 1,
    MIN_ADJUDICATION_TIME_PERIOD: 1,
    MIN_APPROVED_SINGLE_CAN_BE_INITIATED_DELAY: 1,
    MIN_APPROVED_SINGLE_RELEASE_RESOLVE_DELAY: 1,
    MIN_RESOLUTION_AUTO_START_DELAY: 1,
    MIN_RESOLUTION_AUTO_START_FORCED_DELAY: 1,
    MIN_RESOLUTION_AUTO_START_NOTIFICATION_DELAY: 1,
    MIN_RELEASE_REQUEST_COMPLAINT_INITIATION_DELAY: 1,
    MIN_RESOLUTION_TIME_PERIOD: 1,
    MAX_ANNUAL_RATE_PERCENT: 100,
    MIN_TENANT_INTEREST_TIME_FRAME_DAYS: 1,
    MAX_TENANT_INTEREST_TIME_FRAME_DAYS: 2147483647,
    MIN_CHARITY_ORGANIZATION_NAME_LENGTH: 1,
    MAX_CHARITY_ORGANIZATION_NAME_LENGTH: 70,
    MIN_CHARITY_ORGANIZATION_ID_LENGTH: 1,
    MAX_CHARITY_ORGANIZATION_ID_LENGTH: 10,
    CHARITY_ORGANIZATION_ID_FORMAT: /^[a-zA-Z0-9]*$/,
    CHARITY_ORGANIZATION_URL_FORMAT:
        /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    NON_LATIN_LETTERS_FORMAT: /^[a-zA-Z0-9!@#$%^&*()_+\-~`<>.,'"/?{|}~.:;'\s]*$/,
    MIN_CHARITY_ORGANIZATION_DESCRIPTION_LENGTH: 1,
    MAX_CHARITY_ORGANIZATION_DESCRIPTION_LENGTH: 1000,
};

export const INTERCOM_SETTINGS = {
    isIntercomIdExist: Boolean(window.APP_SETTINGS.intercomClientId),
    isIntercomEnabledForInternalUser: Boolean(window.APP_SETTINGS.enableIntercomChatForInternalUser),
};

export const FILTER_CONSTANTS = {
    CHECKED: "checked",
};

export const DEFAULT_COUNTRY_UK_ID = 225;

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_VARIANTS = [10, 20, 50, 100];

export const DEFAULT_DEBOUNCE_INTERVAL = 1000;

export const MAXIMUM_VOUCHERS_COUNT = 9999999;
export const MAXIMUM_TENANCY_DETAILS_POUNDS_FLOAT = 9999999.99;
export const MAXIMUM_TENANCY_DETAILS_DEPOSIT_POUNDS_FLOAT = 99999999.99;
export const STAR = "*";

export const ACCOUNT_TYPES: number[] = [
    AccountType.Admin,
    AccountType.PrivateLandlord,
    AccountType.CorporateLandlord,
    AccountType.Tenant,
    AccountType.Agent,
    AccountType.None,
];

export const MultilineTextFieldDefaults = {
    DEFAULT_ROWS: 2,
    MAX_ROWS: 10,
};

export const DEFAULT_COUNTRY_KEY = "gb";
export const PREFERRED_COUNTRIES: string[] = ["gb"];

const DOC_TYPES = ".doc,.docx,.odt";
const EXCEL_TYPES = ".xls,.xlsx,.ods";
export const CSV_TYPES = ".csv";
const TXT_TYPES = ".txt";
export const PDF_TYPES = "application/pdf";
const DOC_TYPES_FOR_CLAIM_EVIDENCES = ".doc,.docx";

export const IMAGES_MIME_TYPES = "image/*";
export const HEIF_TYPES = ".heic";
export const EMAIL_TYPES = ".eml";
export const IMAGES_TYPES = `${IMAGES_MIME_TYPES},${HEIF_TYPES}`;
export const VIDEOS_TYPES =
    ".webm,.mpg,.mp2,.mp1,.mpeg,.mpe,.mpv,.ogg,.mp4,.m4p,.m4v,.avi,.wmv,.mov,.qt,.flv,.swf,.avchd,.mkv";

export const ALLOWED_AVATAR_TYPES = ".jpg,.jpeg,.gif,.png";
export const ALLOWED_SIGNATURE_TYPES = ".svg,.png";
export const ALLOWED_COURT_TYPES = `${PDF_TYPES},${IMAGES_TYPES},${EMAIL_TYPES}`;
export const ALLOWED_DOCUMENTS_TYPES = `${PDF_TYPES},${IMAGES_TYPES},${VIDEOS_TYPES},${DOC_TYPES},${EXCEL_TYPES},${EMAIL_TYPES}`;
export const ALLOWED_EVIDENCES_TYPES = `${ALLOWED_DOCUMENTS_TYPES}`;
export const ALLOWED_CLAIM_EVIDENCES_TYPES = `${DOC_TYPES_FOR_CLAIM_EVIDENCES},${PDF_TYPES},${IMAGES_TYPES}`;
export const ALLOWED_BULK_UPLOAD_TYPES = `${CSV_TYPES},${TXT_TYPES}`;
export const ALLOWED_STATUTORY_DECLARATION_TYPES = `${IMAGES_TYPES},${PDF_TYPES},${EMAIL_TYPES}`;

export const ALLOWED_EVIDENCES_FILE_SIZE = 256 * 1024 * 1024;
export const ALLOWED_NOTES_FILE_SIZE = 256 * 1024 * 1024;
export const ALLOWED_TENANT_INTEREST_LOGO_FILE_SIZE = 5 * 1024 * 1024;

export const THUMBNAIL = {
    WIDTH: 160,
    HEIGHT: 160,
    LOAD_DELAY_MS: 3000,
    LOAD_TIME_TO_TRY: 180 * 1000,
    LOAD_MAX_TRIES: 60,
};

export const AVATAR = {
    WIDTH: 256,
    HEIGHT: 256,
};

export const CHAT_PAGE_SIZE = 50;
export const CHAT_GROUP_SPLIT_ON_DELAY_MS = 5 * 60 * 1000;
export const NOTIFICATIONS_PAGE_SIZE = 10;

export const GALLERY_START_SLIDER = 1;
export const GALLERY_SLIDE_SPACING = 8;

export const PAGE_HAS_BEEN_FORCE_REFRESHED_KEY = "pageHasBeenForceRefreshed";

export const DEFAULT_CHART_SETTINGS = {
    tooltip: {
        enabled: false,
    },

    legend: {
        show: false,
    },
};

export const POLLING_INTERVAL_BULK_UPLOAD_MS = 10000;

export const PUBLIC_FILES_FOLDER_PATH = `${window.location.origin}/files`;
export const BULK_UPLOAD_PUBLIC_FILE_TEMPLATE_EXTERNAL = `${PUBLIC_FILES_FOLDER_PATH}/bulk_upload_template_external.csv`;
export const BULK_UPLOAD_PUBLIC_FILE_TEMPLATE_INTERNAL = `${PUBLIC_FILES_FOLDER_PATH}/bulk_upload_template_internal.csv`;

export const CLAIM_CLOSED_STATUSES = [
    ClaimStatusEnum.ClosedDeclined,
    ClaimStatusEnum.ClosedAccepted,
    ClaimStatusEnum.ClosedAcceptedPartially,
];

export const TENANCY_RECALCULATE_TRADITIONAL_DEPOSIT_TIMEOUT_MS = 500;
export const TENANCY_RENEWAL_PERIODIC_YEARS_AMOUNT = 1;

export const DEPOSIT_PROTECTION_START_DATE_MAX_YEARS_FROM_NOW = 1;
export const TENANCY_START_DATE_MAX_YEARS_FROM_NOW = 2;
export const TENANCY_START_DATE_MAX_MONTHS_FROM_NOW = TENANCY_START_DATE_MAX_YEARS_FROM_NOW * 12;
export const END_OF_TENANCY_LEAVING_DATE_MAX_MONTHS_FROM_NOW = 12;

export const NOTIFICATIONS_LIST_MAX_HEIGHT_PX = 656;

export const OFFICES_AMOUNT_BY_DEFAULT = 1;

export const CHECKOUT_SESSION_TIMER_POLLING_INTERVAL = 1000;

export const MAX_CERTIFICATE_GENERATION_WAIT_TIME_MS = 180000;

export const BANK_ACCOUNT_NUMBER_MASK = "99999999";
export const BANK_SORT_CODE_MASK = "99-99-99";

export const CARD_HAS_BEEN_DELETED_ERROR_CODE = "payment_intent_incompatible_payment_method";

export const HISTORICAL_TENANCIES_SELECT_MIN = 1;
export const HISTORICAL_TENANCIES_SELECT_MIN_FOR_TENANT = 2;

export const LOOKUP_SEARCH_TEXT_MIN_LENGTH = 3;
export const LOOKUP_DEBOUNCE_MS = 500;

export const ACCRUAL_START_DATE = new Date(2023, 6, 1);

export const SUPPORT_USER_ID = -1;
