export enum MessageValue {
    Address = "Address",
    Amount = "Amount",
    Interest = "Interest",
    BankAccountNumber = "BankAccountNumber",
    ComparisonProperty = "ComparisonProperty",
    ComparisonValue = "ComparisonValue",
    Count = "Count",
    CurrentStep = "CurrentStep",
    Date = "Date",
    Day = "Day",
    Days = "Days",
    DaysToReview = "DaysToReview",
    Deposit = "Deposit",
    Email = "Email",
    EntityName = "EntityName",
    Id = "Id",
    LeadTenant = "LeadTenant",
    Link = "Link",
    Name = "Name",
    Percent = "Percent",
    PropertyManager = "PropertyManager",
    PropertyName = "PropertyName",
    ReleaseRequestId = "ReleaseRequestId",
    Remain = "Remain",
    Stage = "Stage",
    Title = "Title",
    TotalStep = "TotalStep",
    From = "From",
    To = "To",
    AssignmentDate = "AssignmentDate",
}
