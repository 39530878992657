import { ID } from "shared/interfaces/common";

export const ADD_DEPOSIT_TO_BASKET = "addDepositToBasket";
export const ADD_NEW_JOINT_LANDLORD = "addNewJointLandlord";
export const ADD_PROPERTY_ACTION = "addPropertyAction";
export const ADMIN_CHANGE_MEMBERSHIP_PAYMENT_OPTION = "adminChangeMembershipPaymentOption";
export const ADMIN_CREATE_USER = "adminCreateUser";
export const ADMIN_FETCH_ROLES_FOR_CREATE_USER = "adminFetchRolesForCreateUser";
export const APPLY_MEMBERSHIP_VOUCHER = "applyMembershipVoucher";
export const BASKET_PROTECT_DEPOSIT = "basketProtectDeposit";
export const BULK_UPLOAD_INITIAL_DATA = "bulkUploadInitialData";
export const BULK_UPLOAD_LOAD_SELECTION = "bulkUploadLoadSelection";
export const CAN_BE_INVITED_TO_TENANCY_ACTION = "canBeInvitedToTenancyAction";
export const CANCEL_CHANGE_OF_TENANT = "cancelChangeOfTenant";
export const CANCEL_CHECKOUT_SESSION = "cancelCheckoutSession";
export const CHANGE_TENANCY_LEAD_TENANT = "changeTenancyLeadTenant";
export const CHANGE_TENANCY_TENANTS = "changeTenancyTenants";
export const CHECK_LANDLORD_CAN_BE_INVITED_INTO_PROPERTY = "checkLandlordCanBeInvitedIntoProperty";
export const CONFIRM_CHANGES = "confirmChanges";
export const CONFIRM_END_OF_TENANCY = "confirmEndOfTenancy";
export const CONFIRM_MEMBERSHIP = "confirmMembership";
export const CONFIRM_REFUND = "confirmRefund";
export const CONFIRM_RELEASE_REQUEST_BULK_INITIATION = "confirmReleaseRequestBulkInitiation";
export const CONFIRM_TENANT_INTEREST = "confirmTenantInterest";
export const COUNTING_TRADITIONAL_DEPOSIT = "countingTraditionalDeposit";
export const CREATE_DEPOSIT_ACTION = "createDepositAction";
export const CREATE_TENANCY = "createTenancy";
export const CREATE_TENANCY_AND_PROTECT_DEPOSIT = "createTenancyAndProtectDeposit";
export const DELETE_ALL_NOTIFICATIONS = "deleteAllNotifications";
export const DEPOSIT_REQUEST_REQUIRED_LOOKUPS = "depositRequestRequiredLookups";
export const DIALOG_ADDITIONAL_BUTTON_ACTION = "dialogAdditionalButtonAction";
export const DIALOG_PRIMARY_BUTTON_ACTION = "dialogPrimaryButtonAction";
export const EDIT_JOINT_LANDLORD = "editJointLandlord";
export const EXPORT_PAYMENT_CERTIFICATES = "exportPaymentCertificates";
export const EXPORT_PAYMENT_HISTORY = "exportPaymentHistory";
export const EXPORT_PROTECTION_HISTORY = "exportProtectionHistory";
export const FETCH_ADD_NOTE_INITIAL_DATA = "fetchAddNoteInitialData";
export const FETCH_AUTHORIZED_CONTACT = "fetchAuthorizedContact";
export const FETCH_ACTIVITY_GRID_DATA = "fetchActivityGridData";
export const FETCH_BANK_CARD = "fetchBankCard";
export const FETCH_BANK_LOCATIONS = "fetchBankLocations";
export const FETCH_BASKET = "fetchBasket";
export const FETCH_BILLING_DETAILS = "fetchBillingDetails";
export const FETCH_CHANGE_TENANTS_TAB_DETAILS = "fetchChangeTenantsTabDetails";
export const FETCH_CHARITY_COUNT = "fetchCharityCount";
export const FETCH_CHARITY_ORGANIZATION = "fetchCharityOrganization";
export const FETCH_CHECKOUT = "fetchCheckout";
export const FETCH_CLAIMS_DATA = "fetchClaimsData";
export const FETCH_COMPANY_DATA_ACTION = "fetchCompanyDataAction";
export const FETCH_COMPANY_DETAILS = "fetchCompanyDetails";
export const FETCH_COMPANY_ROLES = "fetchCompanyRoles";
export const FETCH_COMPANY_USER_PROFILE = "fetchCompanyUserProfile";
export const FETCH_COMPANY_USERS = "fetchCompanyUsers";
export const FETCH_COMPANY_USERS_COUNT = "fetchCompanyUsersCount";
export const FETCH_COMPLAINT_DETAILS = "fetchComplaintDetails";
export const FETCH_COMPLAINT_REASONS = "fetchComplaintReasons";
export const FETCH_CORRESPONDENCE_ADDRESS = "fetchCorrespondenceAddress";
export const FETCH_COURT_SOURCES = "fetchCourtSources";
export const FETCH_DASHBOARD_WIDGETS = "fetchDashboardWidgets";
export const FETCH_DEFAULT_BANK_DETAILS = "fetchDefaultBankDetails";
export const FETCH_DEFAULT_TRANSFER_METHOD = "fetchDefaultTransferMethod";
export const FETCH_DEPOSIT_AND_PROPERTY_SUMMARY = "fetchDepositAndPropertySummary";
export const FETCH_DEPOSIT_PAYMENT_HISTORY_COUNT = "fetchDepositPaymentHistoryCount";
export const FETCH_DEPOSIT_PAYMENT_INTEREST_START_DATE = "fetchDepositPaymentInterestStartDate";
export const FETCH_DEPOSITS_COUNT = "fetchDepositsCount";
export const FETCH_DEPOSITS_RECENT_ACTIVITIES = "fetchRecentActivities";
export const FETCH_DEPOSITS_UNALLOCATED_FUNDS = "fetchUnallocatedFunds";
export const FETCH_END_OF_TENANCY_CONFIRMATION = "fetchEndOfTenancyConfirmation";
export const FETCH_ERROR_LOG = "fetchErrorLog";
export const FETCH_FAILED_PAYMENTS_COUNT = "fetchFailedPaymentsCount";
export const FETCH_FILTER_LIST = "fetchFilterList";
export const FETCH_HONESTY_QUESTIONS_ACTION = "fetchHonestyQuestionsAction";
export const FETCH_IN_PROGRESS_PAYMENTS = "fetchInProgressPayments";
export const FETCH_LANDLORD_DETAILS = "fetchLandlordsDetails";
export const FETCH_LANDLORDS_INFO = "fetchLandlordsInfo";
export const FETCH_MORE = "fetchMore";
export const FETCH_NOTIFICATIONS = "fetchNotifications";
export const FETCH_OFFICE_DETAILS = "fetchOfficeDetails";
export const FETCH_OFFICE_STAFF_COUNT = "fetchOfficeStaffCount";
export const FETCH_OFFICES_LOOKUP = "fetchOfficesLookup";
export const FETCH_OFFICES_LOOKUP_BULK_UPLOAD = "fetchOfficesLookupBulkUpload";
export const FETCH_PAYMENT_DETAILS = "fetchPaymentDetails";
export const FETCH_PAYMENT_HISTORY_COUNT = "fetchPaymentHistoryCount";
export const FETCH_PAYMENTS_HISTORY_COUNT = "fetchPaymentsHistoryCount";
export const FETCH_POSSIBLE_SINGLE_RELEASE_INITIATORS = "fetchPossibleSingleReleaseInitiators";
export const FETCH_POSSIBLE_UPLOADERS = "fetchPossibleUploaders";
export const FETCH_PRIMARY_USER = "fetchPrimaryUser";
export const FETCH_PROFILE_ACCOUNT = "fetchProfileAccount";
export const FETCH_PROFILE_DATA = "fetchProfileData";
export const FETCH_PROPERTIES_BY_STATUS_WIDGET = "fetchPropertiesByStatusWidget";
export const FETCH_PROPERTY_DETAILS = "fetchPropertyDetails";
export const FETCH_PROPERTY_GRID_DETAILS = "fetchPropertyGridDetails";
export const FETCH_PROPERTY_SUMMARY = "fetchPropertySummary";
export const FETCH_RELEASE_REQUEST_DETAILS_FOR_LINK = "fetchReleaseRequestDetailsForLink";
export const FETCH_RELEASE_REQUEST_STATUSES = "fetchReleaseRequestStatuses";
export const FETCH_RESOLUTION_CASE_DETAILS = "fetchResolutionCaseDetailsAction";
export const FETCH_RESOLUTION_MANAGEMENT = "fetchResolutionManagement";
export const FETCH_RESOLUTION_MANAGEMENT_RECENT_ACTIVITIES = "fetchResolutionManagementActivities";
export const FETCH_RESOLUTION_STAGES = "fetchResolutionStages";
export const FETCH_REVIEW_QUESTIONS = "fetchReviewQuestions";
export const FETCH_ROLE_AND_PERMISSIONS = "fetchRoleAndPermissions";
export const FETCH_ROLE_DETAILS = "fetchRoleDetails";
export const FETCH_SCHEME_CONFIGURATION_DATA = "fetchSchemeConfigurationData";
export const FETCH_SCHEME_RULES = "fetchSchemeRules";
export const FETCH_SCHEME_TENANT_INTEREST = "fetchSchemeTenantInterest";
export const FETCH_SCHEMES = "fetchSchemes";
export const FETCH_SCHEMES_SUMMARY = "fetchSchemesSummary";
export const FETCH_SETTLEMENTS_AMEND_HISTORY = "fetchSettlementsAmendHistory";
export const FETCH_SINGLE_RELEASE = "fetchSingleRelease";
export const FETCH_STATUTORY_DECLARATION = "fetchStatutoryDeclaration";
export const FETCH_STATUTORY_DECLARATIONS = "fetchStatutoryDeclarations";
export const FETCH_TENANCIES_INFO = "fetchTenanciesInfo";
export const FETCH_TENANCY = "fetchTenancy";
export const FETCH_TENANCY_TENANTS = "fetchTenancyTenants";
export const FETCH_TENANT_FREE_LOOKUP = "fetchTenantFreeLookup";
export const FETCH_TENANT_MEMBERSHIP = "fetchTenantMembership";
export const FETCH_TRANSFER_DATA = "fetchTransferData";
export const FETCH_UNALLOCATED_PAYMENT_HISTORY_COUNT = "fetchUnallocatedPaymentHistoryCount";
export const FETCH_USER_CERTIFICATE_DETAILS_FOR_INTERNAL_USER = "fetchUserCertificateDetailsForInternalUser";
export const FETCH_USER_DETAILS_FOR_INTERNAL_USER = "fetchUserDetailsForInternalUser";
export const FETCH_USER_STATUSES = "fetchUserStatuses";
export const FETCH_MID_TERM_RELEASE_DATA = "fetchMidTermReleaseData";
export const FETCH_CONFIRM_MID_TERM_RELEASE_DATA = "fetchConfirmMidTermReleaseData";
export const GET_DEPOSIT_DETAILS_ACTION = "getDepositDetailsAction";
export const GO_TO_CHECKOUT = "goToCheckout";
export const INITIAL_FETCH_USER_INFO_FOR_INTERNAL_USER = "initialFetchUserInfoForInternalUser";
export const LOAD_BANK_ACCOUNT_DETAILS = "loadBankAccountDetails";
export const LOAD_LANDLORDS = "loadLandlords";
export const LOAD_OFFICE_IDS_WITH_CARD_DETAILS = "loadOfficeIdsWithCardDetails";
export const LOAD_POSSIBLE_RR_INITIATORS = "loadPossibleRrInitiators";
export const LOAD_RR_DATA_ACTION = "loadReleaseRequestDataAction";
export const LOAD_RR_DOCUMENTS_ACTION = "loadReleaseRequestDocuments";
export const LOAD_RR_GENERAL_EVIDENCE_ACTION = "loadReleaseRequestGeneralEvidence";
export const LOAD_SETTLEMENTS_ACTION = "loadSettlementsAction";
export const LOAD_SINGLE_RELEASE_DETAILS = "loadSingleReleaseDetails";
export const LOAD_TRANSFER_PAYMENT_DETAILS = "loadTransferPaymentDetails";
export const LOAD_TRANSFER_PAYMENT_ITEMS = "loadTransferPaymentItems";
export const LOAD_USER_ACCOUNTS = "loadUserAccounts";
export const LOAD_VOUCHERS_IN_DEBT_STATE = "loadVouchersInDebtState";
export const LOADING_AUDIT_LOG_ENTRY_DETAILS = "loadingAuditLogEntryDetails";
export const LOGIN_ACTION = "loginAction";
export const MOVE_TO_ADJUDICATOR = "moveToAdjudicator";
export const PAYMENT_HISTORY_REQUEST_REQUIRED_LOOKUPS = "paymentHistoryRequestRequiredLookups";
export const POST_STATUTORY_DECLARATIONS = "postStatutoryDeclarations";
export const PREVIEW_CERTIFICATE = "previewCertificate";
export const PROLONG_CHECKOUT_SESSION = "prolongCheckoutSession";
export const REFETCH_COMPANY_DETAILS = "refetchCompanyDetails";
export const REFETCH_DEPOSITS_UNALLOCATED_FUNDS = "refetchUnallocatedFunds";
export const REFETCH_USER_DETAILS_FOR_INTERNAL_USER = "refetchUserDetailsForInternalUser";
export const REFRESH_ACCESS_TOKEN = "refreshAccessToken";
export const REFRESH_BASKET_ITEMS_COUNT = "refreshBasketItemsCount";
export const REJECT_TENANT_INTEREST = "rejectTenantInterest";
export const RENEW_TENANCY = "renewTenancy";
export const REQUEST_DEACTIVATION_REASONS = "requestDeactivationReasons";
export const RESEND_CODE_ACTION = "resendCodeAction";
export const RESEND_INVITATION = "resendInvitation";
export const RESOLVE_RESOLUTION = "resolveResolution";
export const SAVE_BASKET_ITEMS = "saveBasketItems";
export const SAVE_CARD = "saveCard";
export const SAVE_DOCUMENTS = "saveDocuments";
export const SEND_ABOUT_PROFILE_DATA_ACTION = "sendAboutProfileDataAction";
export const SEND_COMPANY_ADDRESS_ACTION = "sendCompanyAddressAction";
export const SEND_COMPANY_CONTACTS_ACTION = "sendCompanyContactsAction";
export const SEND_COMPANY_DETAILS_ACTION = "sendCompanyDetailsAction";
export const SEND_COMPANY_HONESTY_QUESTIONS_ACTION = "sendCompanyHonestyQuestionsAction";
export const SEND_COMPANY_PRIMARY_CONTACT_ACTION = "sendCompanyPrimaryContactAction";
export const SEND_PROFILE_ADDRESS_DATA_ACTION = "sendProfileAddressDataAction";
export const SETUP_PAYMENT = "setupPayment";
export const SIGN_IN_ACTION = "signInAction";
export const START_CLAIM = "startClaim";
export const START_MEMBERSHIP_WITH_VOUCHER = "startMembershipWithVoucher";
export const SUBMIT_SINGLE_RELEASE = "submitSingleRelease";
export const TENANCIES_REQUEST_REQUIRED_LOOKUPS = "tenanciesRequestRequiredLookups";
export const TRANSFER_TO_UNALLOCATED_FUNDS = "transferToUnallocatedFunds";
export const UPDATE_BANK_ACCOUNT_ID = "updateBankAccountId";
export const UPDATE_DEPOSIT_PAYMENT_INTEREST_START_DATE = "updateDepositPaymentInterestStartDate";
export const UPDATE_LOGO = "updateLogo";
export const UPDATE_MISSING_DETAILS = "updateMissingDetails";
export const UPDATE_OFFICE = "updateOffice";
export const UPDATE_PAYMENT_VARIANT = "updatePaymentVariant";
export const INITIATE_MID_TERM_RELEASE = "initiateMidTermRelease";

export const UPDATE_PROPERTY_DETAILS = "updatePropertyDetails";
export const UPDATE_RESOLUTION_COMMENT = "updateResolutionComment";
export const UPDATE_SCHEME_DETAILS = "updateSchemeDetails";
export const UPDATE_SCHEME_PARAMETERS = "updateSchemeParameters";
export const UPDATE_SETTLEMENT_DETAILS = "updateSettlementDetails";
export const UPDATE_SIGNATURE = "updateSignature";
export const UPDATE_STATUTORY_DECLARATION = "updateStatutoryDeclaration";
export const UPDATE_TENANCY_DETAILS = "updateTenancyDetails";
export const UPDATE_TENANT_INTEREST = "updateTenantInterest";
export const UPDATE_VOUCHERS_SETTINGS = "updateVoucherSettings";
export const VERIFY_COMPANY_REGISTRATION_NUMBER = "verifyCompanyRegistrationNumber";
export const VERIFY_PHONE_NUMBER = "verifyPhoneNumber";

export const ACTIVATING_OFFICE_ACTION = (index?: number): string =>
    index ? `activatingOfficeAction${index}` : "activatingOfficeAction";
export const DELETE_NOTIFICATION = (index: ID): string => `deleteNotification${index}`;

export const isAdditionalButtonAction = (actionName: string): boolean => {
    return actionName === DIALOG_ADDITIONAL_BUTTON_ACTION;
};
export const isPrimaryButtonAction = (actionName: string): boolean => {
    return actionName === DIALOG_PRIMARY_BUTTON_ACTION;
};
