export enum AccountType {
    Admin = 1,
    PrivateLandlord = 2,
    CorporateLandlord = 3,
    Tenant = 4,
    Agent = 5,
    None = 100,
}

export enum ActionState {
    Unavailable = 1,
    Prohibited = 2,
    Available = 3,
}

export enum UserStatus {
    Inactive = 1,
    Dormant = 2,
    Active = 3,
}

export enum AccountStatus {
    Inactive = 1,
    Pending = 2,
    Active = 3,
}

export enum AccountUserStatus {
    Inactive = 1,
    Pending = 2,
    Active = 3,
}

export enum ReleaseRequestCreateActionState {
    Unavailable = 1,
    Available = 2,
    ProhibitedBecauseAlreadyExists = 3,
    ProhibitedBecauseItsTooEarly = 4,
    ProhibitedBecauseOfInProgressPayments = 5,
}

export enum ReleaseRequestStatus {
    Draft = 1,
    Open = 11,
    Negotiation = 21,
    Accepted = 31,
    Court = 41,
    EvidenceReview = 46,
    Resolution = 51,
    Resolved = 55,
    Canceled = 61,
}

export enum SettlementStatus {
    Negotiation = 1,
    Accepted = 2,
    Deleted = 3,
    Rejected = 4,
    Resolved = 5,
    ResolvedByCourt = 6,
}

export enum ProposedBy {
    None = 0,
    LeadTenant = 1,
    PropertyManager = 2,
}

export enum TenancyStatuses {
    Active = 11,
    Registered = 31,
    Incomplete = 51,
    Closed = 71,
}

export enum PropertyStatuses {
    Vacant = 1,
    Occupied = 10,
}

export enum ManagedBy {
    Agency = 1,
    Landlord = 2,
}

export enum EvidenceIconType {
    Loading = 0,
    Document = 1,
    Picture = 2,
}

export enum TimeFrameStatus {
    CanBeOpenInFuture = 1,
    CanBeOpen = 2,
    CanNotBeOpen = 3,
}

export enum ReleaseRequestPartyType {
    None = 0,
    LeadTenant = 1,
    PropertyManager = 2,
}

export enum ClaimStatusEnum {
    NotExists = -1,
    Open = 1,
    OpenAccepted = 11,
    OpenDeclined = 21,
    ClosedAccepted = 31,
    ClosedDeclined = 41,
    ClosedAcceptedPartially = 51,
}

export enum TenancyTenantStatusEnum {
    Pending = 1,
    PreApproved = 2,
    Live = 3,
}

export enum DepositSchemeType {
    NotProtected = -1,
    DepositReplacement = 1,
    Custodial = 2,
}

export enum FrequenciesOfRent {
    Weekly = 1,
    Monthly = 2,
    Annually = 3,
    Quarterly = 4,
    SixMonthly = 5,
}

export enum DepositSchemes {
    Replacement = 1,
    Custodial = 2,
}

export enum DepositStatusEnum {
    FullyProtected = 1,
    NotProtected = 41,
    ProtectionEnded = 101,
}

// https://github.com/mydeposits/RealityStoneAPI/blob/dev/src/Domain/HF.RealityStone.Domain.Models/Deposits/DepositProtectionStatusEnum.cs
export enum DepositProtectionStatusEnum {
    PartiallyProtected = 21,
    WaitingForPayment = 31,
    Settlements = 81,
    WaitingOnResolution = 91,
}

// https://github.com/mydeposits/RealityStoneAPI/blob/dev/src/Domain/HF.RealityStone.Domain.Models/Deposits/DepositReplacementStatusEnum.cs
export enum DepositReplacementStatusEnum {
    Registered = 11,
    PendingTenantAction = 51,
    PendingAgentAction = 61,
    PendingLandlordAction = 71,
}

export type DepositStatus = DepositStatusEnum | DepositProtectionStatusEnum | DepositReplacementStatusEnum;

export enum StripeSessionUsage {
    OnSession = "on_session",
    OffSession = "off_session",
}

export enum PaymentVariant {
    EveryonePays = 1,
    LeadTenantPays = 2,
}

export enum StatutoryDeclarationStatus {
    Pending = 1,
    Rejected = 11,
    Approved = 21,
}

export enum ResolutionOutcomeTypes {
    Rejected = 1,
    Mediated = 2,
    Adjudicated = 3,
    CourtNotInitiated = 4,
    ResolvedPerCourtOutcome = 5,
}

export enum PaymentStatuses {
    Intended = 10,
    Initiated = 20,
    Pending = 30,
    Sent = 40,
    Completed = 50,
    Failed = 60,
    Cancelled = 70,
}

export enum PaymentDirections {
    Incoming = 1,
    Outgoing = 2,
}

export enum SchemeVersionRules {
    EnableReleaseRequestReview = 38,
    ReleaseRequestReviewPeriod = 39,
    EnableReleaseRequestResolutionStage = 26,
}

export enum SchemeRules {
    SingleReleaseCanBeInitiatedDelay = 2,
    ApprovedSingleReleaseResolveDelay = 3,
    ResolutionAutoStartDelay = 4,
    ResolutionForcedAutoStartDelay = 5,
    ResolutionAutoStartNotificationDelay = 6,
    ResolutionTimePeriod = 7,
    ResolutionAdjudicationTimePeriod = 8,
    ResolutionWarningDelay = 9,
    ResolutionAutoStartNotificationReminderDelays = 10,
    ResolutionAutoStartFinalNotificationReminderDelay = 11,
    ResolutionAutoStartInactivityNotificationReminderDelay = 12,
    TurnOnResolutionWarningAndOverdueNotifications = 13,
    ResolverIsNotAssignedRepeatableReminderPeriod = 14,
    ReleaseRequestComplaintInitiationDelay = 15,
    ComplaintCommonEmail = 16,
    SingleReleaseCommonEmail = 17,
    ResolutionCommonEmail = 18,
    CourtTimePeriod = 19,
    StripePublicKey = 21,
    AllowPropertyManagerToInitiateReleaseRequest = 23,
    AllowLeadTenantToInitiateReleaseRequest = 24,
    EnableReleaseRequestSingleRelease = 25,
    EnableReleaseRequestResolutionStage = 26,
    EnableReleaseRequestAdjudicationStage = 27,
    EnableReleaseRequestCourtStage = 28,
    ReleaseRequestInitiationDelay = 29,
    FixedEndOfTenancyHelpEmailDelay = 30,
    PeriodicEndOfTenancyHelpEmailDelay = 31,
    DepositIdPrefix = 32,
    CourtCommonEmail = 33,
    SortCode = 34,
    AccountNumber = 35,
    AccountName = 36,
    PaymentsReceiverAddress = 37,
    EnableReleaseRequestReview = 38,
    ReleaseRequestReviewPeriod = 39,
}

export enum FileGenerationStatus {
    Pending = 1,
    Generated = 2,
}

export enum ConfirmationStatusEnum {
    ConfirmationRequired = 1,
    Approved = 2,
}

export enum SchemeConfigurationComparisonFieldKeysEnum {
    AnnualRate = 1,
    TimeFrameInCalendarDays = 2,
}

export enum InterestBreakdownNoteType {
    DepositBalanceOrAnnualRateUpdated = 1,
    InterestReceivedFromOtherProviders = 2,
    InterestWasChangedManually = 3,
}

export enum MidTermReleaseReasons {
    DepositCap = 1,
    GoodWill = 2,
}

export enum MidTermReleaseStatus {
    Opened = 1,
    InProgress = 10,
    Released = 20,
    Cancelled = 30,
}

export enum BankDetailsType {
    Saved = "1",
    New = "2",
}
